import React from 'react'

import AddChild from './AddChild'

export class NoChildren extends React.Component {
  render() {
    return (
      <div>
        <div>
          <div className="text-center">
            <h1>
              Thank you for participating in our study
            </h1>
            <p className="lead">
              To begin you must register your high school child
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <AddChild />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
