import {axiosInstance} from '../helpers'


const url = '/sessions'


const create = params => axiosInstance.create({url: url, params: params})

const destroy = () => axiosInstance.destroy({url: url, params: null})


export const sessionServices = {
  create,
  destroy,
}
