import {axiosInstance} from '../helpers'


const url = '/re-consent'
const update = params => axiosInstance.update({url: url, params: params})


export const reConsentServices = {
  update,
}
