import React from 'react'

import { store } from '../../helpers'
import { LoginModal } from '../../components/Modals/LoginModal'
import { confirmationServices } from '../../services'
import { alertActions } from '../../actions'
import { Spinner } from '../../components/Spinner'

class Confirmation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      confirmed: false,
      loading: true,
    }

    this.toggleModal = this.toggleModal.bind(this)
    this.handleResendConfirmation = this.handleResendConfirmation.bind(this)
  }

  componentDidMount() {
    const { token } = this.props.match.params
    const { dispatch } = store

    confirmationServices.create(token).then(
      response => {
        this.setState({
          confirmed: true,
          loading: false,
        })
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alert.error(e))
            })
          }
        }

        this.setState({
          loading: false,
        })
      }
    )
  }

  handleResendConfirmation(event) {
    const { dispatch } = store
    const { token } = this.props.match.params

    confirmationServices.update(token).then(
      response => {
        dispatch(alertActions.success("Email sent"))
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  toggleModal() {
    const { showModal } = this.state

    this.setState({
      showModal: !showModal,
    })
  }

  render() {
    const { showModal, confirmed, loading } = this.state

    return (
      <div>
        <LoginModal showModal={showModal} onClose={() => this.toggleModal()} />
        <div className="container mt-4 mb-4">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading">
                ACCOUNT{' '}
                <span className="text-primary">
                  CONFIRMATION:
                </span>
              </h2>
              {loading ? (
                <div className="text-center">
                  <Spinner loading={loading} />
                </div>
              ) : confirmed ? (
                <p className="lead">
                  Your account has been confirmed and you can now{" "}
                  <span className="btn-custom" onClick={this.toggleModal}>
                    login
                  </span>
                </p>
              ) : (
                <div>
                  <p className="lead">
                    Your account could not be confirmed at this time
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleResendConfirmation}
                  >
                    Resend confirmation email
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Confirmation
