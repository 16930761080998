import React from 'react'
import header from '../../images/Consent/Leeds/header.png'

export class LeedsAssent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      researcher_name: 'Ben Jones',  // default to Ben Jones
      child_name: '',
      q_one: '',
      q_two: '',
      q_three: '',
      q_four: '',
      q_five: '',
      q_six: '',
      q_seven: '',
      q_eight: ''
    }
  }

  validateComplete = () => {
    const {
      child_name, q_one, q_two, q_three, q_four, q_five, q_six, q_seven, q_eight
    } = this.state

    let data = this.state
    data.formValid = true

    if (child_name && q_one && q_two && q_three && q_four && q_five && q_six &&
      q_seven && q_eight) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const {name, value} = event.target
    if (['researcher_name', 'child_name'].includes(name)) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    } else if (['q_one', 'q_two', 'q_three', 'q_four', 'q_five', 'q_six', 'q_seven', 'q_eight'].includes(name)) {
      this.setState(
        {
          [name]: event.target.checked,
        },
        this.validateComplete
      )
    }
  }

  render() {
    const {researcher_name, child_name} = this.state

    return (
      <div>
        <img
          src={header}
          alt="header"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: '50px',
            maxWidth: '250px',
          }}
        />
        <br/>
        <h4 style={{textAlign: 'center'}}>PARTICIPANT INFORMATION & CONSENT SHEET</h4>
        <br/>
        <p>
          <b>Injury prevention in female youth rugby union: a multi-site international study</b>
        </p>
        <p>
          Name of Researcher: Professor Keith Stokes
          <br/>
          Contact details of Researcher: k.stokes@bath.ac.uk
        </p>
        <br/>
        <p>
          We would like you to help us with our research project. Please read this information sheet carefully and ask
          us if you are not clear about any details of the project or what taking part would involve for you. Take your
          time to decide if you want to take part. It’s up to you if you want to do this. If you don’t then that’s
          absolutely fine.
        </p>
        <br/>
        <h5>1. Why are we doing this research project?</h5>
        <p>
          This study aims to work with a number of girls youth rugby teams to collect information about how many games
          are played and any rugby-related injuries that occur. This will allow us to measure the level of injury risk
          and highlight how injuries may be prevented in the future.
        </p>
        <p>
          This study aims to work with a number of girls youth rugby teams to collect information about how many games
          are played and any rugby-related injuries that are sustained. This will allow us to measure the level of
          injury risk and highlight any ways in which injuries may be prevented in the future.
        </p>
        <p>
          This study has three major goals
          <ol>
            <li>
              Collect information on injuries and concussions
            </li>
            <li>
              Work out why some girls may have a higher risk of injury and concussion than others when playing rugby
            </li>
            <li>
              Create sport-related concussion prevention programmes and evaluate if these programmes work
            </li>
          </ol>
        </p>
        <br/>
        <h5>2. Why have you been asked to take part?</h5>
        <p>
          Your team is interested in being involved in the study, as they see the potential benefit that this study may
          have. However, it is important that you understand what the study involves and how it may impact you before
          you decide if you want to take part.
        </p>
        <br/>
        <h5>3. Do I have to take part?</h5>
        <p>
          No. It is totally up to you to decide whether or not you want to take part. No one will mind if you say no. If
          you say yes you can still change your mind later, without telling us why.
        </p>
        <p>
          If you do decide to take part in the project, and your parents have given permission, we will ask you to
          complete an online form to say you agree to take part.
        </p>
        <br/>
        <h5>4. What would taking part involve?</h5>
        <p>
          <ul>
            <li>
              You will be asked to answer some questions and do some tests when you enter the study. These will take
              approximately 2 hours and will be done at your school/club. We will ask you questions about sport
              participation, injury history, and different aspects of your life, jump test to see how high you can jump
              and we will ask you for some information that we will use later if you report a concussion
            </li>
            <li>
              You will be asked to keep track of your participation in rugby, and to report any injuries or concussions
              you get during your participation in the study. This will also be done for all players who consent to
              being involved by someone linked to your team (e.g., a coach, a therapist).
            </li>
            <li>
              If you, your parent/guardian or coach thinks you might have a concussion, you will be able to consult a
              study health care professional with expertise in concussion to determine if you have a concussion and give
              you some guidance about what you should do to recover from it fully before going back to playing sports
              and school.
            </li>
            <li>
              Following reporting of an injury, a research team member will arrange to contact you via phone/ email to
              obtain any details about the injury you have reported.
            </li>
            <li>
              You will also repeat some of the tests you completed at the beginning of the study, along with
              questionnaires used following concussion.
            </li>
            <li>
              Some games which you play in will be filmed.
            </li>
            <li>
              You may be asked to help us evaluate ways to reduce injury during the study. This ways we might try to
              reduce injuries include a special training programme, a warm-up, a rule change or a type of equipment.
            </li>
            <li>
              If you choose, you may be in the study for up to 3 years.
            </li>
          </ul>
        </p>
        <br/>
        <h5>5. Are there reasons why I should not take part?</h5>
        <p>
          There are no specific reasons why you should not take part.
        </p>
        <br/>
        <h5>6. What are the benefits of taking part?</h5>
        <p>
          <ul>
            <li>
              The information we get from this study may help us to provide better ways of preventing, diagnosing and
              managing injuries and concussions in youth rugby.
            </li>
            <li>
              If you do get a concussion during the study, you will be able to consult with a health care professional
              with expertise in concussion within 72-hours.
            </li>
          </ul>
        </p>
        <br/>
        <h5>7. What are the possible disadvantages and risks of taking part?</h5>
        <p>
          There are no perceived risks for you in taking part in this study.
          If the questionnaire asks a question that you do not want to answer for any reason, you can choose not to
          answer.
        </p>
        <br/>
        <h5>8. Will my participation involve any discomfort or embarrassment?</h5>
        <p>
          The testing done at the start of the season will not hurt. The physical activity measurements described above
          will be done under close supervision and every effort will be made to ensure your safety. If you have any
          painful areas in your body, please let the testing staff know at the start of testing and some of the tests
          may be modified or left out. As with any physical activity, there is the possibility of a muscle strain for
          tests, such as running.
        </p>
        <br/>
        <h5>9. Who will have access to the information that I provide?</h5>
        <p>
          All information which is collected about you during the course of the research project will be treated as
          confidential and kept on a password protected file on the University of Bath’s secure server (X drive). This
          information will be stored in accordance with current UK data protection legislation. All information that you
          provide will only be able to be accessed by the researchers at the University of Bath and University of
          Calgary.
        </p>
        <br/>
        <h5>10. What will happen to the data collected and results of the project?</h5>
        <p>
          Following rules set out by the University of Bath, and current UK data protection legislation, all personal
          data will be destroyed at the end of the analysis apart from the information you provide to say that you will
          take part in the project (your consent form), which will be stored securely for 10 years. The information will
          be destroyed after 10 years in a secure way. Anonymised data will be retained for analysis but it will not be
          possible to identify any individual in the data that is retained.
        </p>
        <p>
          Data will be analysed by researchers at the University of Bath and University of Calgary (Canada). We will
          produce a report and write scientific articles about the current risks of injury in youth rugby. However, no
          personal references to you or your team will be made in any report.
        </p>
        <br/>
        <h5>11. Who has reviewed the project?</h5>
        <p>
          This project has been given a favourable opinion by the University of Bath, Research Ethics Approval Committee
          for Health (REACH) [reference: EP 22 073].
        </p>
        <br/>
        <h5>12. How can I stop taking part in the project after it has started?</h5>
        <p>
          Should you wish to withdraw from the study, you may request to have your data withdrawn up until two weeks
          after the conclusion of each playing season by sending an email with your name to rfu-youth@bath.ac.uk. If you
          withdraw, no additional data will be collected relating to you. If you withdraw from the study, you may
          request to withdraw all of the data already collected as well.
        </p>
        <br/>
        <h5>13. University of Bath privay notice</h5>
        <p>
          The University of Bath privacy notice can be found here:
          <br/>
          <a rel="noreferrer" target="_blank"
             href="https://www.bath.ac.uk/corporate-information/university-of-bath-privacy-notice-for-research-participants/">
            https://www.bath.ac.uk/corporate-information/university-of-bath-privacy-notice-for-research-participants/
          </a>
        </p>
        <br/>
        <h5>14. What happens if there is a problem?</h5>
        <p>
          If you have any questions about the study, please contact or have your parents/guardians contact the primary
          researcher, Keith Stokes; email: rfu-youth@bath.ac.uk. If they are unable to resolve your concern or you wish
          to make a complaint regarding the project, please contact the Chair of the Research Ethics Approval Committee
          for Health: Professor James Betts; email: <i>health-ethics@bath.ac.uk</i>
        </p>
        <br/>
        <h5>If I require further information who should I contact and how?</h5>
        <p>
          You can contact [name of the researcher] at the University of Bath who will be happy to answer any questions
          that you have. Please do also talk to your parent/guardian about your decision whether to take part in the
          project.
        </p>
        <p>
          Name of Researcher: Professor Keith Stokes
          <br/>
          Contact details of Researcher: <i>k.stokes@bath.ac.uk</i>
        </p>
        <hr/>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand why this project is being done and what taking part will involve for me. I understand that this
            project is for University of Bath research.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_one"
                  id="q_one"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I have been able to ask questions about the project and have received answers to my questions.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_two"
                  id="q_two"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand that I can stop taking part at any time.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_three"
                  id="q_three"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand that if my Parent or Guardian requests that I stop taking part, my involvement in the project
            will be stopped.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_four"
                  id="q_four"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand who will have access to the information I provide, how the information will be stored, and what
            will happen to the information at the end of the project.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_five"
                  id="q_five"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand the information I provide will be held in line with current UK data protection legislation.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_six"
                  id="q_six"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand that I can ask the researcher to stop using my information up to two weeks after I participate
            in the project. If I do this, my data will not be used in the project or future publications.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_seven"
                  id="q_seven"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I agree to take part in this project.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_eight"
                  id="q_eight"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <hr/>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Your Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              value={child_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Researcher's Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <select
              id="researcher_name"
              name="researcher_name"
              className="form-control"
              value={researcher_name}
              onChange={this.handleChange}
            >
              <option key="benJones">Ben Jones</option>
            </select>
          </div>
        </div>
        <hr/>
      </div>
    )
  }
}
