import React from 'react'
import { withRouter } from 'react-router-dom'
import Notifications from 'react-notify-toast'

import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { Banner } from '../../components/Banner/Banner'

class App extends React.Component {
  render() {
    const landingPage = this.props.location
      ? this.props.location.pathname === '/'
      : false

    return (
      <div style={{ overflowX: 'hidden' }}>
        <Notifications />
        <Header />
        {!landingPage && <Banner />}
        <div
          className={!landingPage ? 'mt-page mb-page' : ''}
          style={{ minHeight: '50vh' }}
        >
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default withRouter(App)
