import React from 'react'
import header from '../../images/Consent/Leeds/header.png'

export class EdinburghConsent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      parent_name: '',
      researcher_name: 'Debbie Palmer', // default to Debbie Palmer
      child_name: '',
      q_one: '',
      q_two: '',
      q_three: '',
      q_four: '',
      q_five: '',
      q_six: '',
      q_seven: '',
      q_eight: '',
      q_nine: '',
      q_ten: '',
      q_eleven: '',
      q_twelve: '',
      q_thirteen: '',
      q_fourteen: '',
      q_fifteen: ''
    }
  }

  validateComplete = () => {
    const {
      parent_name, child_name, q_one, q_two, q_three, q_four, q_five, q_six, q_seven,
      q_eight, q_nine, q_ten, q_eleven, q_twelve, q_thirteen, q_fourteen, q_fifteen
    } = this.state

    let data = this.state
    data.formValid = true

    if (parent_name && child_name && q_one && q_two && q_three && q_four && q_five && q_six &&
      q_seven && q_eight && q_nine && q_ten && q_eleven && q_twelve && q_thirteen && q_fourteen && q_fifteen) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const {name, value} = event.target
    if (['parent_name', 'researcher_name', 'child_name'].includes(name)) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    } else if (['q_one', 'q_two', 'q_three', 'q_four', 'q_five', 'q_six', 'q_seven',
      'q_eight', 'q_nine', 'q_ten', 'q_eleven', 'q_twelve', 'q_thirteen', 'q_fourteen', 'q_fifteen'].includes(name)) {
      this.setState(
        {
          [name]: event.target.checked,
        },
        this.validateComplete
      )
    }
  }

  render() {
    const {researcher_name, parent_name, child_name} = this.state

    return (
      <div>
        <img
          src={header}
          alt="header"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: '50px',
            maxWidth: '250px',
          }}
        />
        <br/>
        <h4 style={{textAlign: 'center'}}>PARENT/GUARDIAN INFORMATION & CONSENT SHEET</h4>
        <br/>
        <p>
          <b>Injury prevention in female youth rugby union: a multi-site international study</b>
        </p>
        <p>
          Name of Researcher: Professor Keith Stokes
          <br/>
          Contact details of Researcher: k.stokes@bath.ac.uk
        </p>
        <br/>
        <p>
          We would like to invite your child to help us with our research project. This information sheet forms part of
          the process of informed consent. It should give you the basic idea of what the research is about and what
          your child’s participation will involve. Please read this information sheet carefully and ask one of the
          researchers named above if you are not clear about any details of the project.
        </p>
        <br/>
        <h5>1. What is the purpose of the project?</h5>
        <p>
          Rugby is one of the most popular sports played in England. As with all sports, there are risks involved with
          rugby and it is in the interest of all stakeholders to make the game as safe as possible.
        </p>
        <p>
          This study aims to work with a number of girls youth rugby teams to collect information about how many games
          are played and any rugby-related injuries that are sustained. This will allow us to measure the level of
          injury risk and highlight any ways in which injuries may be prevented in the future.
        </p>
        <p>
          We will also be filming some youth rugby matches, which we will analyse to better understand the physical
          demands of the game at different age groups
        </p>
        <p>
          This study has six three major goals:
          <br/>
          <ol>
            <li>
              Collect information on injuries and concussions
            </li>
            <li>
              Work out why some students may have a higher risk of injury and concussion than others when playing rugby
            </li>
            <li>
              Create sport-related concussion prevention programmes and evaluate if these programmes reduce the risk of
              injury and concussion.
            </li>
          </ol>
        </p>
        <br/>
        <h5>2. Why has my child been selected to take part</h5>
        <p>
          Your child’s team has expressed an interest in the study as they see the potential benefit that this study
          may have.
        </p>
        <br/>
        <h5>3. Does my child have to take part?</h5>
        <p>
          It is completely up to you to decide if you would like your child to participate. If you agree you would like
          your child to take part, we will ask you to complete a Parent/Guardian consent form. However, it at any time
          you decide you no longer wish your child to take part in this project you are free to withdraw their
          participation, without giving a reason.
        </p>
        <p>
          Your child’s involvement in their team will not be affected if they choose not to consent to take part in the
          study.
        </p>
        <p>
          If you give consent, your child will then be provided with information about this project. Should your child
          wish to take part, they will be provided with a Young Person’s assent form to complete to show that they agree
          to take part.
        </p>
        <br/>
        <h5>4. What will my child be asked to do?</h5>
        <p>
          We will be asking school students participating in rugby union (ages 14-18) across England to be in the study.
          All participants will be asked to attend a baseline testing session at the start of the study, during which
          they will be asked to complete a variety of measurements. This will take approximately 2 hours to complete and
          will occur in your child’s school or club. Your child may be involved in the study for up to three seasons. We
          will ask for consent at the beginning of each season and most of the baseline tests/questionnaires will be
          repeated each year.
        </p>
        <p>
          Measurements for all participants will include:
          <br/>
          <ol>
            <li>
              Completion of the following questionnaires online
              <ol type='a'>
                <li>
                  Baseline Questionnaire (e.g., demographics, medical history, sport participation).
                  Completed by your child.
                </li>
                <li>
                  Weekly self-report of participation hours in sport and any physical complaints and injuries that they
                  sustain playing rugby.
                </li>
              </ol>
            </li>
            <li>
              The following tests and physical assessments of your child administered by qualified research staff.
              Testing will be completed online or at your child’s school or club
              <ol>
                <li>
                  Sport Concussion Assessment Tool 5 (SCAT5)
                </li>
                <li>
                  Height and weight measurement
                </li>
                <li>
                  Vertical jump score: using a jump mat to measure height achieved.
                </li>
              </ol>
            </li>
            <li>
              If you, your child, the coach, or other team personnel suspects that your child has sustained a
              concussion, your child will have the opportunity to follow-up with a health care professional with
              expertise in concussion within 72-hours online via telehealth. The health care professional will recommend
              what your child should do to recover from concussion before returning to sports. Your child can continue
              consulting the health care professional until cleared to return to playing sport. Your child will be asked
              to repeat the baseline measurements described above after recovering from a concussion.
            </li>
            <li>
              In order to better understand how injury occurs in the sport, some (but not all) matches will be recorded.
              This will allow us to investigate the structure of the game, the events in which injuries are occurring as
              well as to look at specific events such as the tackle event. Furthermore, to better understand how warm-up
              programs may affect injury risk, some pre-game warm ups may be captured on video also.
            </li>
          </ol>
          <br/>
        </p>
        <h5>5. What are the exclusion criteria? </h5>
        <b>(are there reasons why my child should not take part)?</b>
        <p>
          <br/>
          Participation is completely optional but if your child is playing rugby for their team, there is no reason
          why they should not take part.
        </p>
        <br/>
        <h5>6. What are the possible benefits of taking part? </h5>
        <p>
          There are some direct benefits to you or your child. The information we get from this study will give
          researchers a better understanding of youth rugby injuries. This information will help develop strategies that
          can be used to prevent sport and recreational injuries in the future. The schools/clubs of students
          participating in the study will have access to concussion education opportunities delivered by the research
          team. A research team member will support assessment and reporting of injuries via phone / video call. All
          study participants will have access to follow-up with a study health care professional with expertise in
          sport-related concussion within 72 hours of reporting a suspected concussion via online telehealth.
        </p>
        <br/>
        <h5>7. What are the possible disadvantages and risks of taking part?</h5>
        <p>
          There are no disadvantages of your child taking part in this study.
        </p>
        <br/>
        <h5>8. Will my participation involve any discomfort or embarrassment?</h5>
        <p>
          As with any physical activity, there is the possibility of a muscle strain for tests, such as running. Some
          testing may result in dizziness or muscle fatigue for a short time following the tests. The risk of injury
          will be reduced by careful supervision by trained research team members during testing procedures. If an
          increase in discomfort occurs above what is typically expected during testing or if your child wishes to
          stop testing, your child should let the tester know. If your child does have any symptoms at any time during
          testing, they should let the tester know and the test will be stopped.
        </p>
        <br/>
        <h5>9. Who will have access to the information that I provide?</h5>
        <p>
          Only the research team from the University of Calgary and University of Bath will have access to information
          that your child provides. All records will be treated as confidential.
        </p>
        <br/>
        <h5>10. What will happen to the data collected and results of the project?</h5>
        <p>
          All information collected throughout the study period will be de-identified and will remain strictly
          confidential. Only the investigators responsible for this study, the research team members directly supervised
          by a study investigator, and the team statistician who will analyze the data will have access to this
          information. Data will be collected primarily through an online web-based customized platform
          (<a rel="noreferrer" target="_blank" href="https://shredconcussions.ca/">https://shredconcussions.ca/</a>)
          with authentication
          for users, encryption, and password protection in accordance with the{' '}
          <a rel="noreferrer" target="_blank" href="https://www.bath.ac.uk/corporate-information/research-data-policy/">
            University of Bath Research Data Policy</a>{' '}
          and the <a rel="noreferrer" target="_blank" href="https://www.legislation.gov.uk/ukpga/2018/12/contents">
          UK Data protection Act 2018</a>.
        </p>
        <p>
          Confidentiality will be protected by using only study identification numbers in the database. Any results of
          the study, which are reported, will in no way identify study participants. Online surveys may ask for personal
          identifiers or information that may be used to identify your child. However, no direct link is made between
          their information and their data. De-identified data may be used in future studies in alignment with this
          project. No medical data outside of study data collection will be accessed by the research team.
        </p>
        <p>
          We will produce a report and write scientific articles about the current risks of injury in youth rugby.
          However, no personal references to your child will be made in any report.
        </p>
        <br/>
        <h5>11. Who has reviewed the project?</h5>
        <p>
          This project has been given a favourable opinion by the University of Bath, Research Ethics Approval Committee
          for Health (REACH) [reference: EP 22 073].
        </p>
        <br/>
        <h5>12. How can I withdraw from the project?</h5>
        <p>
          Participation in the study is voluntary and you can withdraw your child from the study at any time by
          contacting the study coordinator without providing a reason for doing so and without any repercussions. If
          your child withdraws from the study, you and your child may request to withdraw their data from the study, as
          well.
        </p>
        <br/>
        <h5>13. University of Bath privacy notice</h5>
        <p>
          The University of Bath privacy notice can be found here:
          <br/>
          <a rel="noreferrer" target="_blank"
             href="https://www.bath.ac.uk/corporate-information/university-of-bath-privacy-notice-for-research-participants/">
            https://www.bath.ac.uk/corporate-information/university-of-bath-privacy-notice-for-research-participants/</a>
        </p>
        <br/>
        <h5>14. What happens if there is a problem?</h5>
        <p>
          If you have a concern about any aspect of the project you should ask to speak to the researchers who will do
          their best to answer any questions. If they are unable to resolve your concern or you wish to make a complaint
          regarding the project, please contact the Chair of the Research Ethics Approval Committee for Health:
          <br/>
          <br/>
          Professor James Betts
          <br/>
          Email: <i>health-ethics@bath.ac.uk</i>
        </p>
        <br/>
        <h5>If I require further information who should I contact and how?</h5>
        <p>
          Please do not hesitate to get in touch with us if you would like some more information.
        </p>
        <p>
          Name of Researcher: Professor Keith Stokes
          <br/>
          Contact details of Researcher: <i>k.stokes@bath.ac.uk</i>
        </p>
        <br/>
        <hr/>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I confirm I am the Parent/Guardian
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_one"
                  id="q_one"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I have been provided with information explaining what my child’s participation in this project involves.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_two"
                  id="q_two"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I have had an opportunity to ask questions and discuss this project.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_three"
                  id="q_three"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I have received satisfactory answers to all questions I have asked.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_four"
                  id="q_four"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I have received enough information about the project to make a decision about my child’s participation.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_five"
                  id="q_five"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand that I am free to withdraw my child from the project at any time during data collection without
            having to give a reason for withdrawing.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_six"
                  id="q_six"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand that I am free to withdraw my child’s data within two weeks of their participation.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_seven"
                  id="q_seven"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand my child will be asked if they agree to participate and if they choose not to, their decision
            will be respected.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_eight"
                  id="q_eight"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand the session will stop if my child asks or appears uncomfortable.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_nine"
                  id="q_nine"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand the nature and purpose of the procedures involved in this project. These have been communicated
            to me on the information sheet accompanying this form.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_ten"
                  id="q_ten"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand and acknowledge that the investigation is designed to promote scientific knowledge and that the
            University of Bath will use the data my child provides only for the purpose(s) set out in the information
            sheet.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_eleven"
                  id="q_eleven"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand the data my child provides will be treated as confidential, and that on completion of the
            project my child’s name or other identifying information will not be disclosed in any presentation or
            publication of the research.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_twelve"
                  id="q_twelve"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I understand my consent to use the data provided by my child is conditional upon the University complying
            with its duties and obligations under current data protection legislation.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_thirteen"
                  id="q_thirteen"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I consent to my child’s data being shared within the research team.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_fourteen"
                  id="q_fourteen"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-6 col-form-label">
            I hereby fully and freely consent to my child’s participation in this project.
          </label>
          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="q_fifteen"
                  id="q_fifteen"
                  onClick={this.handleChange}
                />
              </label>
            </div>
          </div>
        </div>
        <hr/>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Parent/Guardian Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_name"
              name="parent_name"
              value={parent_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Child Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              value={child_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Researcher Name:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <select
              id="researcher_name"
              name="researcher_name"
              className="form-control"
              value={researcher_name}
              onChange={this.handleChange}
            >
              <option key="debbiePalmer">Debbie Palmer</option>
            </select>
          </div>
        </div>
        <hr/>
      </div>
    )
  }
}
