import React from 'react'
import { Bell } from 'react-feather'
import { Edit2 } from 'react-feather'

export class ChildActions extends React.Component {
  render() {
    const {
      handleChildEdit,
      handleReminder,
      handleNewConcussionReminder,
    } = this.props

    return (
      <div>
        <div>
          <span
            className="btn-custom-muted"
            onClick={handleChildEdit}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Edit2 size={16} className="mr-2" />
            Edit Child
          </span>
        </div>
        <div>
          <span
            className="btn-custom-muted"
            onClick={handleReminder}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Bell size={16} className="mr-2" />
            Send your child a reminder to complete their surveys
          </span>
        </div>
        <div>
          <span
            className="btn-custom-muted"
            onClick={handleNewConcussionReminder}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Bell size={16} className="mr-2" />
            Send your child a reminder to report their recent concussion
          </span>
        </div>
      </div>
    )
  }
}
