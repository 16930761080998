import React from 'react'

import {store} from '../../../helpers/index'
import ConsentFormModal from '../../Modals/ConsentFormModal'
import {userServices} from '../../../services'
import {alertActions, userActions} from '../../../actions'
import PhoneInput from "react-phone-number-input";


export class Personal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      first_name: '',
      last_name: '',
      phone_number: '',
      notify: '',
      frequency: '',
      city: '',
      showConsentModal: false,
      school_id: '',
      school_dirty: false,
      club_id: '',
      club_dirty: false,
      schools: [],
      clubs: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleConsentModal = this.toggleConsentModal.bind(this)
  }

  componentDidMount() {
    const {dispatch} = store

    userServices.get().then(
      response => {
        const data = response.data.data

        this.setState({
          first_name: data.first_name ? data.first_name : '',
          last_name: data.last_name ? data.last_name : '',
          phone_number: data.phone_number ? data.phone_number : '',
          notify: data.notify ? parseInt(data.notify.value, 10) : '',
          frequency: data.frequency ? parseInt(data.frequency.value, 10) : '',
          role: data.role ? data.role : '',
          signature: data.signature ? data.signature : '',
          created: data.created ? data.created : '',
          city: data.city ? data.city : '',
          school_id: data.school_id ? data.school_id : '',
          school_dirty: data.school_id ? false : true,
          club_id: data.club_id ? data.club_id : '',
          club_dirty: data.club_id ? false : true,
          schools: data.schools ? data.schools : [],
          clubs: data.clubs ? data.clubs : []
        })
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  handleChange(event, type = null) {

    let name = ''
    let value = ''

    if (type === 'phone_number') {
      name = 'phone_number'
      value = event
    } else {
      name = event.target.name
      value = event.target.value
    }

    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    const {
      first_name,
      last_name,
      phone_number,
      frequency,
      notify,
      school_id,
      club_id
    } = this.state
    const {dispatch} = store

    dispatch(userActions.update({first_name, last_name, phone_number, notify, frequency, school_id, club_id}))
  }

  toggleConsentModal() {
    const {showConsentModal} = this.state

    this.setState({
      showConsentModal: !showConsentModal,
    })
  }

  render() {
    const {
      first_name,
      last_name,
      phone_number,
      notify,
      frequency,
      showConsentModal,
      signature,
      role,
      created,
      city,
      school_id,
      club_id,
      clubs,
      schools,
      school_dirty,
      club_dirty
    } = this.state

    return (
      <div className="mt-5 mb-5">
        <ConsentFormModal
          showModal={showConsentModal}
          signatureAllowed={false}
          signature={signature}
          created={created}
          city={city}
          onClose={this.toggleConsentModal}
          role={role}
        />
        {city && (
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <span className="btn-custom" onClick={this.toggleConsentModal}>
                {role === 'parent'
                  ? "Review and print the " +
                  ' ' +
                  "Consent"
                  : "Review and print the " +
                  ' ' +
                  "Assent"}{' '}
                Form
              </span>
            </label>
          </div>
        )}
        <h5>
          Profile
        </h5>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              First name
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="text"
                className="form-control"
                name="first_name"
                value={first_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              Last name
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="text"
                className="form-control"
                name="last_name"
                value={last_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              Phone number
            </label>
            <div className="col-lg-3 mb-2">
              <PhoneInput
                className="form-control"
                name="phone_number"
                defaultCountry="GB"
                value={phone_number}
                onChange={value => this.handleChange(value, 'phone_number')}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              How would you like to be notified?
            </label>
            <div className="col-lg-3 mb-2">
              <select
                id="notify"
                className="form-control"
                name="notify"
                value={notify}
                onChange={this.handleChange}
              >
                <option value={1}>
                  email
                </option>
                <option value={2}>
                  text
                </option>
                <option value={0}>
                  both
                </option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              How often would you like to receive reminders?
            </label>
            <div className="col-lg-3 mb-2">
              <select
                id="frequency"
                className="form-control"
                name="frequency"
                value={frequency}
                onChange={this.handleChange}
              >
                <option value={1}>
                  weekly
                </option>
                <option value={0}>
                  never
                </option>
              </select>
            </div>
          </div>
          {
            this.props.role === 'child' ? (
                <div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">
                      School/College
                    </label>
                    <div className="col-lg-3 mb-2">
                      <select
                        id="school_id"
                        className="form-control"
                        name="school_id"
                        value={school_id}
                        onChange={this.handleChange}
                        disabled={school_id && !school_dirty ? true : false}
                      >
                        {school_dirty ? <option selected value=''></option> : null}
                        {schools.map(el => <option value={el['id']}>{el['name']}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">
                      Club
                    </label>
                    <div className="col-lg-3 mb-2">
                      <select
                        id="club_id"
                        className="form-control"
                        name="club_id"
                        value={club_id}
                        onChange={this.handleChange}
                        disabled={club_id && !club_dirty ? true : false}
                      >
                        {club_dirty ? <option selected value=''></option> : null}
                        {clubs.map(el => <option value={el['id']}>{el['name']}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
              )
              : null
          }
          <div className="form-group">
            <button type="submit" className="btn btn-primary mt-2">
              Save
            </button>
          </div>
        </form>
      </div>
    )
  }
}
