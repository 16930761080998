import React from 'react'
import { push } from 'connected-react-router'

import { store } from '../../../helpers/index'
import qs from 'qs'

import AddChild from './AddChild'
import Athlete from './Athlete'
import { Tabs } from './Tabs'
import { recordsActions } from '../../../actions/index'
import EditChildModal from '../../Modals/EditChildModal'

class Children extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      children: props.children,
      showAdd: false,
      activeChildTab: 0,
      showModal: false,
      doomedChild: '',
    }

    this.cancelAdd = this.cancelAdd.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleOptOut = this.handleOptOut.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidMount() {
    const { location, children } = this.props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.childTab && children && children.length) {
        this.setState({
          children: children,
          doomedChild:
            search.childTab === 'add' ? '' : children[search.childTab],
          activeChildTab: search.childTab,
          showAdd: search.childTab === 'add',
        })
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, children } = props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.childTab && children && children.length) {
        state.children = children
        state.doomedChild =
          search.childTab === 'add' ? '' : children[search.childTab]
        state.activeChildTab = search.childTab
        state.showAdd = search.childTab === 'add'
      }
    }

    return state
  }

  cancelAdd() {
    const { dispatch } = store

    dispatch(
      push({
        pathname: '/profile/dashboard',
        search: '?childTab=0&contentTab=baseline',
      })
    )
  }

  handleTabChange(tab) {
    const { dispatch } = store
    const { location } = this.props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.contentTab) {
        dispatch(
          push({
            pathname: '/profile/dashboard',
            search: '?childTab=' + tab + '&contentTab=' + search.contentTab,
          })
        )

        this.setState({
          activeChildTab: tab,
        })
      }
    }
  }

  toggleModal() {
    const { showModal } = this.state

    this.setState({
      showModal: !showModal,
    })
  }

  handleDelete() {
    const { dispatch } = store
    const { doomedChild } = this.state

    dispatch(recordsActions.destroy('type=delete&baseline_portal_id=' + doomedChild.baseline_portal_id))

    this.childRemoved(doomedChild)
  }

  handleOptOut() {
    const { dispatch } = store
    const { doomedChild } = this.state

    dispatch(recordsActions.destroy('type=opt_out&baseline_portal_id=' + doomedChild.baseline_portal_id))

    this.childRemoved(doomedChild)
  }

  childRemoved(doomed_child) {
    const { dispatch } = store
    let { children } = this.state

    const index = children.findIndex(
      i => i.baseline_portal_id === doomed_child.baseline_portal_id
    )
    if (index >= 0) {
      children.splice(index, 1)
      sessionStorage.setItem('records', JSON.stringify(children))
    }

    this.toggleModal()

    dispatch(
      push({
        pathname: '/profile/dashboard',
        search: '?childTab=0&contentTab=baseline',
      })
    )
  }

  render() {
    const { role, location } = this.props
    const {
      children,
      showAdd,
      activeChildTab,
      showModal,
      doomedChild,
    } = this.state

    return (
      <div>
        <EditChildModal
          showModal={showModal}
          child={doomedChild}
          tabName={
            doomedChild.first_name
              ? doomedChild.first_name
              : 'Child ' + (+activeChildTab + 1)
          }
          onDelete={this.handleDelete}
          onOptOut={this.handleOptOut}
          onClose={this.toggleModal}
        />
        <Tabs
          children={children}
          handleTabChange={this.handleTabChange}
          activeTab={activeChildTab}
        />
        {showAdd ? (
          <AddChild handleCancel={this.cancelAdd} />
        ) : (
          <Athlete
            record={children[activeChildTab]}
            activeChildTab={activeChildTab}
            handleChildEdit={this.toggleModal}
            role={role}
            location={location}
          />
        )}
      </div>
    )
  }
}

export default Children
