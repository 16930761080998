import React from 'react'

import {connect} from "react-redux";

import {parentConsentReminderServices} from "../../services";
import {alertActions, sessionActions} from "../../actions";
import {push} from "connected-react-router";
import {store} from "../../helpers";


class ParentConsentRequired extends React.Component {
  constructor(props) {
    super(props)

    this.handleRemind = this.handleRemind.bind(this)
  }

  handleRemind() {
    const {dispatch} = store

    parentConsentReminderServices.create({}).then(
      response => {
        dispatch(sessionActions.destroy())
        dispatch(alertActions.success("Reminder has been sent. Please login once again once parent consent has been submitted"))
      }, error => {
        dispatch(alertActions.success("Something went wrong"))
      }
    )
  }

  render() {
    return (<div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-9">
          {this.props.parentEmail ? (<>
            <h2 className="heading">
              Parent Consent{' '}
              <span className="text-primary">Required</span>
            </h2>
            <p>
              Click the button below to send a reminder email to {this.props.parentEmail}
            </p>
            <button
              onClick={() => this.handleRemind()}
              type="submit"
              name="submit"
              className="btn btn-primary mt-5"
            >
              Send Reminder
            </button>
          </>) : null}
        </div>
      </div>
    </div>)
  }
}

function mapStateToProps(state) {
  return {
    parentEmail: state.session.parentEmail
  }
}

export default connect(mapStateToProps)(ParentConsentRequired)
