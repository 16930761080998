import React from 'react'

export class Status extends React.Component {
  render() {
    const { survey } = this.props

    return (
      <div style={{ fontSize: '15px' }}>
        {survey.instrument === 'pbq_sports_enrollment' ? (
          <span className="warning-color">
            Edit
          </span>
        ) : survey.availability && !survey.completed && !survey.partial ? (
          <span className="primary-color">
            Available
          </span>
        ) : survey.availability && !survey.completed && survey.partial ? (
          <span className="warning-color">
            Not finished
          </span>
        ) : survey.available > 0 ? (
          <span className="muted-color">
            Available in{' '}{survey.available}{' '}
            {survey.available > 1 ? (
              "days"
            ) : (
              "day"
            )}
          </span>
        ) : (
          <span className="success-color">
            Complete
          </span>
        )}
      </div>
    )
  }
}
