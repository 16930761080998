import React from 'react'

import { store } from '../../../helpers/index'
import { userActions } from '../../../actions/index'
import ConfirmationModal from '../../Modals/ConfirmationModal'

export class Delete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteConfirmationModal: false,
      optoutConfirmationModal: false,
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleOptOut = this.handleOptOut.bind(this)
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
    this.toggleOptoutModal = this.toggleOptoutModal.bind(this)
  }

  handleDelete() {
    const { dispatch } = store

    dispatch(userActions.destroy('type=delete'))

    this.setState({ deleteConfirmationModal: false })
  }

  toggleDeleteModal() {
    const { deleteConfirmationModal } = this.state
    this.setState({
      deleteConfirmationModal: !deleteConfirmationModal,
    })
  }

  toggleOptoutModal() {
    const { optoutConfirmationModal } = this.state
    this.setState({
      optoutConfirmationModal: !optoutConfirmationModal,
    })
  }

  handleOptOut() {
    const { dispatch } = store

    dispatch(userActions.destroy('type=opt_out'))

    this.setState({ optoutConfirmationModal: false })
  }

  render() {
    const { deleteConfirmationModal, optoutConfirmationModal } = this.state

    return (
      <div className="mb-5">
        <div className="row">
          <div className="col-lg-6">
            <h5>
              Delete
            </h5>
            <p>
              Delete my account but you can keep my data for the purpose of this study.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <button
              type="submit"
              className="btn btn-danger"
              onClick={this.toggleDeleteModal}
            >
              Delete
            </button>
          </div>
          <ConfirmationModal
            showModal={deleteConfirmationModal}
            onClose={this.toggleDeleteModal}
            handleAction={this.handleDelete}
            message="Deleting your account is irreversible. All content will be removed in the process, and cannot be restored."
            action="delete"
          />
          <ConfirmationModal
            showModal={optoutConfirmationModal}
            onClose={this.toggleOptoutModal}
            handleAction={this.handleOptOut}
            message="Opting out your account is irreversible. All content will be removed in the process, and cannot be restored."
            action="opt out"
          />
        </div>
      </div>
    )
  }
}
