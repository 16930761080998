import {axiosInstance} from '../helpers'


const url = '/parent-consent-reminder'
const create = params => axiosInstance.create({url: url, params: params})


export const parentConsentReminderServices = {
  create,
}
