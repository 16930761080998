import {axiosInstance} from '../helpers'


const url = '/sport'

const get = () => axiosInstance.get({url: url, params: null})

export const sportServices = {
  get,
}
