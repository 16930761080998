import React from 'react'

import { Mail } from 'react-feather'
import { Phone } from 'react-feather'


export const ContactUs = () => (
  <div className="container mt-4 mb-4">
    <div className="row">
      <div className="col-lg-12">
        <h2 className="heading mb-5">
          contact{' '}
          <span className="text-primary">
            us
          </span>
        </h2>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <h5 className="mb-4">
          HOW TO CONTACT US:
        </h5>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <address className="bg-light p-3">
          Study Coordinator: Dr. Simon Roberts
          <br />
          Department for Health
          <br />
          University of Bath,
          <br />
          Claverton Down,
          <br />
          Bath,
          <br/>
          BA27AY
          <br />
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Mail className="mr-2" />
            rfu-shred@bath.ac.uk
            <br />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Phone className="mr-2" />
            +44 (0) 1225384531
          </div>
        </address>
      </div>
      <div className="col-lg-6">
        <address className="bg-light p-3">
          Principal Investigator: Dr. Keith Stokes
          <br />
          Department for Health
          <br />
          University of Bath,
          <br />
          Claverton Down,
          <br />
          Bath,
          <br/>
          BA27AY
          <br />
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Mail className="mr-2" />
            spsks@bath.ac.uk
            <br />
          </div>
        </address>
      </div>
    </div>
  </div>
)
