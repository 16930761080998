import React from 'react'

import SurveyModal from '../Modals/SurveyModal'
import { Status } from './Status'
import { StatusIcon } from './StatusIcon'
import { store } from '../../helpers/index'
import { mobileSurveys } from '../../constants'
import { recordsActions, alertActions } from '../../actions'
import { surveyServices } from '../../services/survey.services'
import PBQModal from '../Modals/PBQ/PBQModal'

class SurveyItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSurveyModal: false,
      showPBQModal: false
    }

    this.handleAction = this.handleAction.bind(this)
  }

  closeModal = () => {
    this.setState({
      showSurveyModal: false,
      showPBQModal: false
    })
  }

  handleAction() {
    const {
      record,
      survey,
      surveyType,
      surveyPath,
      handleInjuryChange,
    } = this.props
    const { dispatch } = store
    const baseline_portal_id = record.baseline_portal_id
    const concussion_portal_id = record.concussion_portal_id

    let records = JSON.parse(sessionStorage.getItem('records'))
    const index = records.findIndex(
      i => i.baseline_portal_id === baseline_portal_id
    )

    if (index >= 0) {
      const j = records[index].surveys[surveyPath].findIndex(
        i => i.type_name === surveyType
      )

      if (j >= 0) {
        const m = records[index].surveys[surveyPath][j].surveys.findIndex(
          i => i.name === survey.name
        )

        if (m >= 0) {
          const portal_id =
            survey.group === 'baseline'
              ? baseline_portal_id
              : concussion_portal_id
          const params = { survey: survey, portal_id: portal_id }
          surveyServices.update(params).then(
            response => {
              records[index].surveys[surveyPath][j].surveys[m] =
                response.data.survey
              records[index].sports = response.data.sports

              dispatch(recordsActions.refresh(records))
            },
            error => {
              if (error.response) {
                const errors = error.response.data.errors
                if (errors) {
                  errors.forEach(e => {
                    dispatch(alertActions.error(e))
                  })
                }
              }
            }
          )
        }
        if (survey.instrument === 'ostrc' && handleInjuryChange)
          handleInjuryChange()
      }
    }
    this.closeModal()
  }

  handleSurveyClick = () => {
    const { survey } = this.props

    if (survey.availability && !survey.completed) {
      if (survey.name === 'PBQ Sports Enrollment') {
        this.setState({showPBQModal: true,})
      } else {
        this.setState({showSurveyModal: true,})
      }
    }
  }

  render() {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)
    const {showSurveyModal, showPBQModal,} = this.state
    const {survey, id, record, surveyPath, surveyType, handleInjuryChange, currentSports} = this.props

    return (
      <div className="col-lg-10">
        <SurveyModal
          showModal={showSurveyModal}
          baseline_portal_id={record.baseline_portal_id}
          concussion_portal_id={record.concussion_portal_id}
          survey={survey}
          surveyType={surveyType}
          surveyPath={surveyPath}
          handleInjuryChange={handleInjuryChange}
          handleAction={this.handleAction}
          onClose={this.closeModal}
        />
        <PBQModal
          showModal={showPBQModal}
          baseline_portal_id={record.baseline_portal_id}
          currentSports={currentSports}
          handleAction={this.handleAction}
          onClose={this.closeModal}
        />
        <div
          className="card"
          style={{ width: '100%', borderRadius: 0, cursor: 'pointer' }}
          id={id}
          onClick={this.handleSurveyClick}
        >
          <div className="card-body" style={{ padding: '0.6rem' }} id={id}>
            <div className="row">
              <div
                style={{
                  marginLeft: '1.3rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <StatusIcon survey={survey} />
                <div style={{ marginLeft: '1.3rem' }}>
                  <h5 style={{ margin: 0 }} id={id}>
                    {isMobile && mobileSurveys[survey.name]
                      ? mobileSurveys[survey.name]
                      : survey.name}
                  </h5>
                  <Status survey={survey} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SurveyItem
