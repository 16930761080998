import React from 'react'
import ReactDOM from 'react-dom'
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux'
import objectFitImages from 'object-fit-images'

import {store, history} from './helpers'
import routes from './routes'
import App from './containers/App'
import './styles/index.scss'
import 'react-phone-number-input/style.css'

objectFitImages()

const app =
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <App>{routes}</App>
        </div>
      </ConnectedRouter>
    </Provider>

ReactDOM.render(app, document.getElementById('root'))
