import React from 'react'
import SurveyItem from './SurveyItem'

export default class SurveyList extends React.Component {
  render() {
    const {surveys, record, surveyPath, surveyType, handleInjuryChange, currentSports} = this.props

    return (
      <div className="row">
        {surveys && surveys.length ? (
          surveys.map((survey, index) => {
            return (
              <SurveyItem
                key={index}
                id={index}
                survey={survey}
                surveyType={surveyType}
                record={record}
                surveyPath={surveyPath}
                handleInjuryChange={handleInjuryChange}
                currentSports={currentSports}
              />
            )
          })
        ) : (
          <p>
            No surveys currently available
          </p>
        )}
      </div>
    )
  }
}
