import {axiosInstance} from '../helpers'


const url = '/city'

const get = () => axiosInstance.get({url: url, params: null})


export const cityServices = {
  get,
}