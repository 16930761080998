import {axiosInstance} from '../helpers'


const url = '/user'

const create = params => axiosInstance.create({url: url, params: params})

const update = params => axiosInstance.update({url: url, params: params})

const get = () => axiosInstance.get({url: url, params: null})

const destroy = type => axiosInstance.destroy({url: `${url}?${type}`, params: null})


export const userServices = {
  create,
  update,
  get,
  destroy,
}
