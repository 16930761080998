import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { logger } from 'redux-logger'

import { routeMiddleware, history } from './history'
import { reducers } from '../reducers'

const middlewares = [];

middlewares.push(thunkMiddleware);
middlewares.push(routeMiddleware);

if (process.env.NODE_ENV === `development`) {
    middlewares.push(logger);
}

export const store = createStore(
    reducers(history),
    compose(applyMiddleware(...middlewares))
);