import {axiosInstance} from '../helpers'


const url = '/recoverable'

const create = params => axiosInstance.create({url: url, params: params})

const update = (params, token) => axiosInstance.update({url: `${url}/${token}`, params: params})


export const recoverableServices = {
  create,
  update,
}