import React from 'react'
import { Plus } from 'react-feather'
import { User } from 'react-feather'


export class Tabs extends React.Component {
  render() {
    const { children, handleTabChange, activeTab } = this.props
    const active = parseInt(activeTab, 10)

    return (
      <ul className="nav nav-pills mb-5" id="patient-tabs" role="tablist">
        {children.map((child, index) => {
          return (
            <li
              className="nav-item"
              key={index}
              onClick={() => handleTabChange(index)}
            >
              <span
                className={active === index ? 'nav-link active' : 'nav-link'}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                id={index}
              >
                <User size={16} className="mr-2" />
                {child.first_name ? child.first_name : 'Child ' + (index + 1)}
              </span>
            </li>
          )
        })}
        <li
          className="nav-item"
          key={99}
          onClick={() => handleTabChange('add')}
        >
          <span
            className={activeTab === 'add' ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            id="add"
          >
            <Plus size={16} className="mr-2" />
            Add Child
          </span>
        </li>
      </ul>
    )
  }
}
