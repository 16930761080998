import React, { useState } from 'react'
import { Transition, animated } from 'react-spring/renderprops'
import { Collapse } from 'reactstrap'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import Login from '../../components/Account/Login'
import homeJPG from '../../images/Home/home.jpg'
import homeWEBP from '../../images/Home/home.webp'
import shredBlack from '../../images/Home/shred_black.png'
import groupPhoto from '../../images/Home/group_photo.jpg'
import dot1 from '../../images/Misc/siprc_dot1.png'
import dot2 from '../../images/Misc/siprc_dot2.png'
import dot3 from '../../images/Misc/siprc_dot3.png'
import dot4 from '../../images/Misc/siprc_dot4.png'
import './home.css'

const items = [
  { image: dot1, key: 1 },
  { image: dot2, key: 2 },
  { image: dot3, key: 3 },
  { image: dot4, key: 4 },
]

export const Home = props => {
  const { isAuthenticated } = props
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)

  const [isOneOpen, setIsOneOpen] = useState(false)
  const [isTwoOpen, setIsTwoOpen] = useState(false)
  const [isThreeOpen, setIsThreeOpen] = useState(false)

  // This got messy...
  const toggle = selector => {
    if (selector === 'one' && isOneOpen && !isTwoOpen && !isThreeOpen) {
      setIsOneOpen(false)
      return
    } else if (selector === 'two' && isTwoOpen && !isOneOpen && !isThreeOpen) {
      setIsTwoOpen(false)
      return
    } else if (
      selector === 'three' &&
      isThreeOpen &&
      !isOneOpen &&
      !isTwoOpen
    ) {
      setIsThreeOpen(false)
      return
    } else if (!isOneOpen && !isTwoOpen && !isThreeOpen) {
      if (selector === 'one') setIsOneOpen(true)
      else if (selector === 'two') setIsTwoOpen(true)
      else setIsThreeOpen(true)
      return
    } else {
      if (selector === 'one') {
        setIsOneOpen(!isOneOpen)
        setIsTwoOpen(false)
        setIsThreeOpen(false)
      } else if (selector === 'two') {
        setIsTwoOpen(!isTwoOpen)
        setIsOneOpen(false)
        setIsThreeOpen(false)
      } else {
        setIsThreeOpen(!isThreeOpen)
        setIsOneOpen(false)
        setIsTwoOpen(false)
      }
    }
  }

  const getDots = data => {
    const items = []
    data.map(item =>
      items.push(
        <img
          key={item.key}
          className="dots dots-mobile m-2"
          src={item.image}
          alt="dot_logo"
        />
      )
    )
    return (
      <Transition
        native={true}
        items={items}
        keys={item => item.key}
        from={{
          transform: 'translate3d(0,-50%,0)',
          opacity: '0',
        }}
        enter={{
          transform: 'translate3d(0,0,0)',
          opacity: '1',
        }}
        trail={175}
      >
        {item => props => <animated.div style={props}>{item}</animated.div>}
      </Transition>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <picture>
        <source srcSet={homeWEBP} type="image/webp" />
        <source srcSet={homeJPG} type="image/jpeg" />
        <img
          className="home-cover"
          alt="background"
          src={homeJPG}
          style={{
            width: '100%',
            objectFit: 'cover',
            borderRadius: `0px`,
          }}
        />
      </picture>
      <div
        className="container"
        style={{
          position: 'absolute',
          left: '52%',
          top: '22%',
          transform: 'translate(-55%, -15vh)',
          zIndex: 1,
        }}
      >
        <div className="row">{getDots(items)}</div>
        <div className="row">
          <div
            className="col-lg-7 p-5 mt-2 card"
            style={{
              boxShadow:
                '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
            }}
          >
            {!isMobile && (
              <div className="mb-4">
                <h1 className="heading m-0">
                  <div
                    className="text-primary"
                    style={{ display: 'absolute', bottom: '0px' }}
                  >
                    <img
                      className="dots dots-mobile m-2"
                      style={{ width: '30%', paddingBottom: '10px' }}
                      src={shredBlack}
                      alt="shredBlack"
                    />
                    CONCUSSIONS UK
                  </div>
                </h1>
                <div className="lead text-primary" style={{marginBottom: '1rem'}}>
                  In collaboration with COMMUNITY RUGBY INJURY SURVEILLANCE AND PREVENTION PROJECT (CRISP)
                  <br/>
                </div>
                <span className="muted-color" style={{fontWeight: 500}}>
                  Developing a greater understanding of injuries and concussion in youth girls rugby
                </span>
              </div>
            )}
            {!isAuthenticated && <Login loginStyle={!isMobile && 'home'} />}
            {!isAuthenticated && (
              <React.Fragment>
                <hr />
                <div style={{ maxHeight: '400', overflowY: 'auto' }}>
                  <p className="muted-color" style={{ fontWeight: 400 }}>
                    More information about the SHRed Research Program supported by the World Rugby and NFL Play Smart Play Safe Program can be found {' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.bath.ac.uk/research-centres/centre-for-health-and-injury-illness-prevention-in-sport-chi2ps/"
                    >
                      here
                    </a>
                  </p>
                  {/*<img*/}
                  {/*  style={{ width: '50%', paddingBottom: '10px' }}*/}
                  {/*  src={groupPhoto}*/}
                  {/*  alt="groupPhoto"*/}
                  {/*/>*/}
                  <p className="muted-color" style={{ fontSize: '90%' }}>
                    If you want to know more about player safety and well-being in rugby in England, visit{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.englandrugby.com/participation/playing/player-welfare-rugby-safe"
                    >
                      RUGBYSAFE
                    </a>
                  </p>
                </div>
              </React.Fragment>
            )}
            {!isAuthenticated && (
              <React.Fragment>
                <hr />
                <div style={{ maxHeight: '345px', overflowY: 'auto' }}>
                  <h5 className="text-primary">
                    <a
                      style={{ textDecoration: 'none' }}
                      href="#!"
                      onClick={() => toggle('one')}
                    >
                      Why should you join?{' '}
                      <span class="text-right">
                        {isOneOpen ? (
                          <IoIosArrowUp style={{ verticalAlign: 'middle' }} />
                        ) : (
                          <IoIosArrowDown style={{ verticalAlign: 'middle' }} />
                        )}
                      </span>
                    </a>
                  </h5>
                  <Collapse isOpen={isOneOpen}>
                    <ul style={{ color: '#665c56' }}>
                      <li>
                        If you suffer a concussion, you have<b> access to see a concussion expert </b>
                        at an affiliated centre of the SHRed Concussions UK project
                      </li>
                      <li style={{ marginTop: '0.5rem' }}>
                        Your participation will make an<b> important contribution to understand concussions </b>
                        in sport to ensure safe participation for everyone
                      </li>
                    </ul>
                  </Collapse>
                  <h5 class="text-primary">
                    <a
                      style={{ textDecoration: 'none' }}
                      href="#!"
                      onClick={() => toggle('two')}
                    >
                      What would you need to do?{' '}
                      {isTwoOpen ? (
                        <IoIosArrowUp style={{ verticalAlign: 'middle' }} />
                      ) : (
                        <IoIosArrowDown style={{ verticalAlign: 'middle' }} />
                      )}
                    </a>
                  </h5>
                  <Collapse isOpen={isTwoOpen}>
                    <ul style={{ color: '#665c56' }}>
                      <li>
                        If you get injured, notify the study therapist and get an assessment
                      </li>
                      <li style={{ marginTop: '0.5rem' }}>
                        Weekly 5 minute online questionnaire on sport participation & injury
                      </li>
                      <li style={{ marginTop: '0.5rem' }}>
                        Annual baseline testing including performance measures, online surveys and other assessments
                      </li>
                    </ul>
                  </Collapse>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
