import React, {createRef} from 'react'
import {push} from 'connected-react-router'

import {store} from '../../helpers/index'
import {alertActions, sessionActions} from '../../actions/index'
import {FormErrors} from '../../components/FormErrors'
import {reConsentServices} from "../../services";
import ConsentFormModal from '../../components/Modals/ConsentFormModal'
import {connect} from "react-redux";

let cancel = {exec: null}

class ReAssent extends React.Component {
  constructor(props) {
    super(props)

    this.assentButtonRef = createRef()

    this.state = {
      city: '',
      signature: '',
      terms: false,
      future_research: false,
      consent_content: {},
      formErrors: {terms: ''},
      termsValid: false,
      formValid: false,
      showConsentModal: false,
      loading: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleConsentModalAccept = this.handleConsentModalAccept.bind(this)
    this.toggleConsentModal = this.toggleConsentModal.bind(this)
  }

  componentWillUnmount() {
    if (cancel.exec) cancel.exec()
  }

  componentDidMount() {
    this.setState({
      city: this.props.city,
    })
  }

  handleChange(event) {
    const {target} = event
    const {name, type} = target

    let value = type === 'checkbox' ? target.checked : target.value
    this.setState({[name]: value}, () => {
      this.validateField(name, value)
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const {
      formValid,
      termsValid,
      signature,
      terms,
      future_research,
      consent_content,
    } = this.state
    const {dispatch} = store

    if (formValid && termsValid) {
      const params = {
        signature,
        terms,
        future_research,
        consent_content,
      }

      this.setState({loading: true})

      reConsentServices.update(params).then(
        response => {
          this.setState({loading: false})
          dispatch(sessionActions.destroy())
          dispatch(alertActions.success("Assent has been re-submitted. New login required."))
        }, error => {
          if (error.response) {
            this.setState({loading: false})
            const errors = error.response.data.errors
            if (errors) {
              errors.forEach(e => {
                dispatch(alertActions.error(e))
              })
            }
          }
        }
      )
    } else if (formValid && !termsValid) this.toggleConsentModal()
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let {termsValid} = this.state

    switch (fieldName) {
      case 'terms':
        termsValid = value === true
        fieldValidationErrors.terms = termsValid ? '' : "'Please read and sign the consent form.'"
        break
      default:
        break
    }

    this.setState(
      {
        termsValid: termsValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    )
  }

  validateForm() {
    const {termsValid} = this.state
    this.setState({
      formValid: termsValid,
    })
  }

  toggleConsentModal() {
    const {showConsentModal} = this.state

    this.setState({
      showConsentModal: !showConsentModal,
    })
  }

  handleConsentModalAccept(signature, future_research, consent_content) {
    let fieldValidationErrors = this.state.formErrors
    fieldValidationErrors.terms = ''

    this.assentButtonRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

    this.setState(
      {
        signature: signature,
        showConsentModal: false,
        terms: !!signature,
        termsValid: !!signature,
        formErrors: fieldValidationErrors,
        future_research: future_research,
        consent_content: consent_content,
      },
      this.validateForm
    )
  }

  render() {
    const {
      formErrors,
      formValid,
      showConsentModal,
      city,
      loading,
    } = this.state

    return (
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-9">
            <h2 className="heading">
              Reconsent{' '}
              <span className="text-primary">
                Required
              </span>
            </h2>
            <div className="mt-2 mb-2">
              <FormErrors formErrors={formErrors}/>
            </div>
            <form name="form" onSubmit={this.handleSubmit}>
              <div className="form-check mt-5">
                <span className="lead">
                  <span style={{color: '#D32F2F'}}>*</span>
                  {"You must read and sign the "}
                  <span
                    className="btn-custom"
                    onClick={this.toggleConsentModal}
                  >
                    assent form
                  </span>
                </span>
              </div>
              <button
                ref={this.assentButtonRef}
                type="submit"
                name="submit"
                className="btn btn-primary mt-5"
                disabled={!formValid || loading}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <ConsentFormModal
          showModal={showConsentModal}
          signatureAllowed={true}
          city={city}
          role={'child'}
          onClose={this.toggleConsentModal}
          onAccept={this.handleConsentModalAccept}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.session.isAuthenticated,
    state: state.session.state,
    storeToken: state.session.token,
    requiresConsent: state.session.requiresConsent,
    role: state.session.role,
    city: state.session.city
  }
}

export default connect(mapStateToProps)(ReAssent)
