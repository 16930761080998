import { BathConsent } from './BathConsent'
import { BathAssent } from './BathAssent'
import { EdinburghConsent } from './EdinburghConsent'
import { EdinburghAssent } from './EdinburghAssent'
import { LeedsConsent } from './LeedsConsent'
import { LeedsAssent } from './LeedsAssent'
import { LondonConsent } from './LondonConsent'
import { LondonAssent } from './LondonAssent'


export const consentForms = {
  Bath: BathConsent,
  Edinburgh: EdinburghConsent,
  Leeds: LeedsConsent,
  London: LondonConsent,
}

export const assentForms = {
  Bath: BathAssent,
  Edinburgh: EdinburghAssent,
  Leeds: LeedsAssent,
  London: LondonAssent,

}

export const consentContentRequired = {
  Bath: true,
  Leeds: true,
  Edinburgh: true,
  London: true,
}

export const assentContentRequired = {
  Bath: true,
  Leeds: true,
  Edinburgh: true,
  London: true,
}
