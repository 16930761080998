import React from 'react'

import ReactModal from 'react-modal'
import Modal from 'react-modal'
import { XCircle } from 'react-feather'

import './Modal.css'

class NewConcussionModal extends React.Component {
  componentDidMount() {
    Modal.setAppElement('body')
  }

  render() {
    const { showModal, onClose, onContinue } = this.props

    return (
      <div>
        <ReactModal
          style={{
            overlay: { backgroundColor: 'rgba(33, 33, 33, 0.85)' },
            content: {
              maxWidth: 700,
              maxHeight: 350,
              width: '80%',
              margin: 'auto',
            },
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={onClose}
        >
          <XCircle
            size={32}
            onClick={onClose}
            style={{ cursor: 'pointer', float: 'right' }}
          />
          <div className="container mt-5 text-center">
            <h4>
              New Concussion Detected
            </h4>
            <p className="lead muted-color">
              You have answered 'Yes' to experiencing a concussion in the last 7 days
            </p>
            <p className="lead">
              Please complete the New Concussion form to report it
            </p>
            <div
              className="justify-content-center"
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '2rem',
              }}
            >
              <div style={{ width: '12px' }} />
              <button className="btn btn-primary" onClick={onContinue}>
                Continue
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}

export default NewConcussionModal
