import React from 'react'
import ReactTooltip from 'react-tooltip'
import { HelpCircle } from 'react-feather'

export const Section = props => {
  const { heading, role } = props
  const headArr = heading && heading.split(' ')
  let tip = ''

  if (role === 'parent') {
    if (heading === "Baseline Surveys")
      tip = "Please complete the baseline surveys for each of your children at the start of the study. RED means the survey is not yet complete, ORANGE means you have partially completed, and GREEN means the survey has been completed"
    else if (heading === "Concussion Start")
      tip = "Please complete the concussion start surveys as soon as possible after you have sustained a concussion"
    else if (heading === "Week 1")
      tip = "The week 1 surveys are unavailable until 7 days after date of concussion. Please complete these surveys within the first week they become available"
    else if (heading === "Bi Weekly")
      tip = "The bi weekly surveys are unavailable until 21 days after date of concussion. After 21 days, they will continue to be available on a bi-weekly schedule"
    else if (heading === "Return to Play")
      tip = "Please complete the return to play surveys once your chile has been cleared by their therapist to return to active sports"
    else if (heading.includes("Information"))
      tip = "Here you will find information on your child’s account and injury status. In the event of an injury you will find additional information related to their injury status"
  } else if (role === 'child') {
    if (heading === "Baseline Surveys")
      tip = "Please complete the baseline surveys at the start of the study. RED means the survey is not yet complete, ORANGE means you have partially completed, and GREEN means the survey has been completed"
    else if (heading === "Weekly" || heading === 'Injury Weekly')
      tip = "Please complete the weekly surveys at the start of each week. Once complete, they will be unavailable until the following Monday"
    else if (heading === "Concussion Start")
      tip = "Please complete the concussion start surveys as soon as possible after you have sustained a concussion"
    else if (heading === "Week 1")
      tip = "The week 1 surveys are unavailable until 7 days after date of concussion. Please complete these surveys within the first week they become available"
    else if (heading === "Bi Weekly")
      tip = "The bi weekly surveys are unavailable until 21 days after date of concussion. After 21 days, they will continue to be available on a bi-weekly schedule"
    else if (heading === "Return to Play")
      tip = "Please complete the return to play surveys once you have been cleared by your therapist to return to active sports"
    else if (heading === "New Concussion")
      tip = "If you have obtained a new concussion, please complete the form below to report it"
    else if (heading === "New Non-Concussion Injury")
      tip = "If you have obtained a new non-concussion injury, please complete the form below to report it"
    else if (heading === "Supplemental Participation")
      tip = "The supplemental participation surveys provide additional opportunities for completing some select surveys"
    else if (heading === "Sport Specific Questionnaires")
      tip = "The sport specific questionnaires become available once you have indicated participation in a sport on the Weekly Exposure survey"
  }

  return (
    <div className="mt-4 mb-3">
      {headArr && (
        <div>
          {tip && (
            <ReactTooltip
              id="tooltip"
              place="right"
              effect="solid"
              type="light"
              multiline
              className="description-tooltip"
            />
          )}
          <h4
            className="d-inline-block mb-4 ml-2"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {headArr.shift() + ' '}
            <span className="text-primary">{headArr.join(' ')}</span>
          </h4>
          <HelpCircle
            size={22}
            className="ml-2 accent-color-1"
            style={{ cursor: 'pointer' }}
            data-tip={tip}
            data-for={'tooltip'}
          />
        </div>
      )}
    </div>
  )
}
