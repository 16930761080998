import React from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

import { store } from '../../helpers'

export class About extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
    const { dispatch } = store

    dispatch(push('/signup'))
  }

  render() {
    const { isAuthenticated } = this.props

    return (
      <div>
        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <h2 className="heading mb-5">
                    an
                    <span className="text-primary">
                      {" introduction"}
                    </span>
                  </h2>
                </div>
                <div className="col-lg-3 col-sm-12 mb-5">
                  {!isAuthenticated && (
                    <div className="text-center">
                      <h5 className="mb-2">
                        SIGN UP NOW!
                      </h5>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.handleClick}
                      >
                        Click here to sign up
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <h5 className="mb-4">
                The SHRed CONCUSSIONS STUDY
              </h5>
              <p className="text-justify mb-5">
                Thank you for your participation in SHRed Concussions UK (Surveillance in High Schools to Reduce the
                Risk of Concussions and their Consequences). Reducing the burden of concussions in youth is paramount.
                SHRed Concussions UK is part of an international research study funded by the National Football League’s
                Scientific Advisory Board, World Rugby, the Rugby Football Union, University of Bath and Leeds Beckett
                University. There are four Canadian and two British Universities involved, as well as more than 30
                community, government and industry partners across two continents. The primary objective of SHRed
                Concussions UK is to develop a greater understanding of concussions and their consequences among high
                school athletes with a focus on Prevention, Detection, Assessment, and Treatment. Thank you for your
                important contribution through participation in SHRed Concussions UK.
                <br/>
                <br/>
              </p>
              <h5 className="mb-4">
                ETHICS APPROVAL
              </h5>

              <p className="mb-5 text-justify">
                SHRED Concussions has been approved by the Research Ethics Board at the University of Calgary
                (REB18-2107), University of Bath (EP 22 073).
              </p>
              <div className="col-lg-6 col-sm-12 pl-0">
                <div className="well pl-3 pr-3">
                  <h5 className={'mb-3 mt-3 text-primary'}>
                    How to sign up:
                  </h5>
                  <ol>
                    <li className="mb-1">
                      Have your parents go to <b>shred.concussions.co.uk</b>
                    </li>
                    <li className="mb-1">
                      Have your parents create a <b>parent account</b>
                    </li>
                    <ul>
                      <li>
                        Enter their email and create a password
                      </li>
                      <li>
                        Read and sign the parental consent form
                      </li>
                    </ul>
                    <li className="mb-1">
                      A message from no-reply@concussions.co.uk will be sent to your parents email address asking them to confirm their account
                    </li>
                    <li className="mb-1">
                      Your Parent must login to their account at https://shred.concussions.co.uk
                    </li>
                    <li className="mb-1">
                      Parents click on the "<b>Add Child</b>" tab
                    </li>
                    <ul>
                      <li>
                        Enter child's birthdate, name, email, phone, school and or club
                      </li>
                    
                      <li>
                        Click "Add Child"
                      </li>
                    
                      <li>
                        Complete the "Additional Info" Survey
                      </li>
                    </ul>
                    <li className="mb-1">
                      A message from no-reply@concussions.co.uk will be sent to your email address asking you to confirm your account
                    </li>
                    <ul>
                      <li>
                        Click link in email
                      </li>
                      <li className="mb-1">
                        Create your password
                      </li>
                      <li>
                         Click the red "Assent" text to read and sign the assent form and click "Agree" then click "Activate"
                      </li>
                    </ul>
                    <li>
                      Sign into your account
                    </li>
                    <ul> 
                      <li>
                        Please complete all baseline questionnaires on your account
                      </li>
                      <li>
                        Remember to log in weekly to complete online injury surveys
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { isAuthenticated } = state.session

  return {
    isAuthenticated,
  }
}

export default connect(mapStateToProps)(About)
