import React from 'react'
import { Link } from 'react-router-dom'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Mail } from 'react-feather'
import { Phone } from 'react-feather'

import siprc from '../../images/Affiliates/siprc.png'
import bath from '../../images/Affiliates/bath.png'
import leeds from '../../images/Affiliates/leeds.png'
import rugby from '../../images/Affiliates/rugby.png'
import uofe from '../../images/Affiliates/uofe.png'
import scottishRugby from '../../images/Affiliates/scottishRugby.png'
import './Footer.css'

export const Footer = () => {
  const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <footer className="footer">
      <div className="footer-secondary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12" style={{ cursor: 'grab' }}>
              <Slider {...settings}>
                <div>
                  <img
                    className="item affiliates"
                    alt="siprc logo"
                    src={siprc}
                    height={80}
                    width="auto"
                    style={{margin: 'auto', maxWidth: 'fit-content', width: '100%'}}
                  />
                </div>
                <div>
                  <img
                    className="item affiliates"
                    alt="nfl logo"
                    src={rugby}
                    height={80}
                    width="auto"
                    style={{margin: 'auto'}}
                  />
                </div>
                <div>
                  <img
                    className="item affiliates"
                    alt="shred logo"
                    src={bath}
                    height={80}
                    width="auto"
                    style={{margin: 'auto'}}
                  />
                </div>
                <div>
                  <img
                    className="item affiliates"
                    alt="shred logo"
                    src={uofe}
                    height={80}
                    width="auto"
                    style={{margin: 'auto'}}
                  />
                </div>
                <div>
                  <img
                    className="item affiliates"
                    alt="shred logo"
                    src={leeds}
                    height={80}
                    width="auto"
                    style={{margin: 'auto'}}
                  />
                </div>
                <div>
                  <img
                    className="item affiliates"
                    alt="shred logo"
                    src={scottishRugby}
                    height={80}
                    width="auto"
                    style={{margin: 'auto'}}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-primary footer-background-color">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-2 footer-widget">
              <h5 className="heading text-white">
                Quick
                <span className="text-primary">
                  {" Links"}
                </span>
              </h5>
              <ul className="quick-links">
                <li>
                  <Link to="/about" className="btn-custom-muted">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className="btn-custom-muted">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 footer-widget">
              <h5 className="heading text-white">
                Contact
                <span className="text-primary">
                  Us
                </span>
              </h5>
              <address>
                <p className="text-primary">
                  SHRed Concussion-Bath
                </p>
                <span>
                  Department for Health,
                  <br />
                  University of Bath,
                  <br />
                  Claverton Down,
                  <br />
                  BA27AY
                </span>
                <br />
                <br />
              </address>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="text-primary"
              >
                <Mail size={16} className="mr-2" />
                shred@bath.ac.uk
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="text-primary"
              >
                <Phone size={16} className="mr-2" />
                +44 (0) 122 538 4531
              </div>
            </div>
            <div className="col-lg-3 footer-widget">
              <h5 className="heading text-white">
                <wbr/>
              </h5>
              <address>
                <p className="text-primary">
                  SHRed Concussions-Leeds
                </p>
                <span>
                  Leeds Beckett University,
                  <br />
                  Carnegie School of Sport,
                  <br />
                  Cavendish Hall 101,
                  <br />
                  Headingly Campus,
                  <br/>
                  LS6 3QS
                </span>
                <br />
                <br />
              </address>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="text-primary"
              >
                <Mail size={16} className="mr-2" />
                b.jones@leedsbeckett.ac.uk
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="text-primary"
              >
                <Phone size={16} className="mr-2" />
                +44 (0) 113 812 4009
              </div>
            </div>
            <div className="col-lg-4 footer-widget">
              <h5 className="heading text-white">
                <wbr/>
              </h5>
              <address>
                <p className="text-primary">
                  SHRed Concussion-Edinburgh
                </p>
                <span>
                  Moray House School of Education and Sport, ISPEHS
                  <br />
                  University of Edinburgh,
                  <br />
                  St Leonard’s Land,
                  <br />
                  Holyrood Road,
                  <br />
                  Edinburgh,
                  <br />
                  EH8 8AQ
                </span>
                <br />
                <br />
              </address>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="text-primary"
              >
                <Mail size={16} className="mr-2" />
                Scotlandrugbystudy@ed.ac.uk
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="text-primary"
              >
                <Phone size={16} className="mr-2" />
                +44 (0) 131 651 4188
              </div>
            </div>
            <span className="copyright">
              &copy; Copyright SHRED 2022
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
