import React from 'react'

import { store } from '../../../helpers/index'
import { FormErrors } from '../../FormErrors'
import { userActions } from '../../../actions/index'

class Password extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      current_password: '',
      new_password: '',
      password_confirmation: '',
      passwordValid: false,
      passwordConfirmationValid: false,
      passwordFormErrors: { password: '', password_confirmation: '' },
      passwordFormValid: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState({ [name]: value }, () => {
      this.validatePasswordField(name, value)
    })
  }

  handleSubmit(event) {
    const {
      passwordFormValid,
      new_password,
      password_confirmation,
      current_password,
    } = this.state
    const { dispatch } = store

    event.preventDefault()

    if (passwordFormValid) {
      dispatch(userActions.update({current_password, new_password, password_confirmation}))

      this.setState({
        current_password: '',
        new_password: '',
        password_confirmation: '',
      })
    }
  }

  validatePasswordField(fieldName, value) {
    let fieldValidationErrors = this.state.passwordFormErrors
    let { passwordValid, passwordConfirmationValid } = this.state
    let { new_password, password_confirmation } = this.state

    switch (fieldName) {
      case 'new_password':
        if (value !== '') {
          passwordValid = value.length >= 6
          fieldValidationErrors.password = passwordValid ? '' : "Password must be 6 characters long."
          if (passwordValid) {
            passwordConfirmationValid =
              value === password_confirmation &&
              password_confirmation.length > 0
            fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : "Passwords do not match."
          }
        } else {
          passwordValid = false
          passwordConfirmationValid = false
          fieldValidationErrors.password = ''
          fieldValidationErrors.password_confirmation = ''
        }
        break
      case 'password_confirmation':
        if (value !== '') {
          passwordConfirmationValid = value === new_password
          fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : "Passwords do not match."
        } else passwordConfirmationValid = false
        if (passwordValid)
          fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : "Passwords do not match."
        else fieldValidationErrors.password_confirmation = ''
        break
      default:
        break
    }

    this.setState(
      {
        passwordValid: passwordValid,
        passwordConfirmationValid: passwordConfirmationValid,
        passwordFormErrors: fieldValidationErrors,
      },
      this.validatePasswordForm
    )
  }

  validatePasswordForm() {
    const { passwordValid, passwordConfirmationValid } = this.state
    this.setState({
      passwordFormValid: passwordValid && passwordConfirmationValid,
    })
  }

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error'
  }

  render() {
    const {
      current_password,
      new_password,
      password_confirmation,
      passwordFormErrors,
    } = this.state

    return (
      <div className="mb-5">
        <h5>
          Change Password
        </h5>
        <div className="mt-2 mb-2">
          <FormErrors formErrors={passwordFormErrors} />
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label className="col-lg-2 col-form-label">
              Current Password
            </label>
            <div className="col-lg-4">
              <input
                type="password"
                className="form-control"
                name="current_password"
                value={current_password}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-2 col-form-label">
              New Password
            </label>
            <div className="col-lg-4">
              <input
                type="password"
                className="form-control"
                name="new_password"
                value={new_password}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-2 col-form-label">
              Confirm
            </label>
            <div className="col-lg-4">
              <input
                type="password"
                className="form-control"
                name="password_confirmation"
                value={password_confirmation}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary mt-2"
              disabled={!this.state.passwordFormValid}
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default Password
