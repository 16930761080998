import React from 'react'
import { push } from 'connected-react-router'

import { store } from '../../helpers'
import { alertActions } from '../../actions'
import { FormErrors } from '../../components/FormErrors'
import { recoverableServices } from '../../services'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      password_confirmation: '',
      formErrors: { password: '', password_confirmation: '' },
      passwordValid: false,
      passwordConfirmationValid: false,
      formValid: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value)
      }
    )
  }

  handleSubmit(event) {
    event.preventDefault()
    const { formValid, password, password_confirmation } = this.state
    const { dispatch } = store

    if (formValid) {
      const { token } = this.props.match.params

      recoverableServices
        .update({ password, password_confirmation }, token)
        .then(
          response => {
            dispatch(push('/'))
            dispatch(alertActions.success("Password set"))
          },
          error => {
            if (error.response) {
              const errors = error.response.data.errors
              if (errors) {
                errors.forEach(e => {
                  dispatch(alertActions.error(e))
                })
              }
            }
          }
        )
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let { passwordValid, passwordConfirmationValid } = this.state
    let { password, password_confirmation } = this.state

    switch (fieldName) {
      case 'password':
        if (value !== '') {
          passwordValid = value.length >= 6
          fieldValidationErrors.password = passwordValid ? '' : "Password must be 6 characters long."
          if (passwordValid) {
            passwordConfirmationValid =
              value === password_confirmation &&
              password_confirmation.length > 0
            fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : "Passwords do not match."
          }
        } else {
          passwordValid = false
          passwordConfirmationValid = false
          fieldValidationErrors.password = ''
          fieldValidationErrors.password_confirmation = ''
        }
        break
      case 'password_confirmation':
        if (value !== '') {
          passwordConfirmationValid = value === password
          fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : "Passwords do not match."
        } else passwordConfirmationValid = false
        if (passwordValid)
          fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : "Passwords do not match."
        else fieldValidationErrors.password_confirmation = ''
        break
      default:
        break
    }

    this.setState(
      {
        passwordValid: passwordValid,
        passwordConfirmationValid: passwordConfirmationValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    )
  }

  validateForm() {
    const { passwordValid, passwordConfirmationValid } = this.state
    this.setState({ formValid: passwordValid && passwordConfirmationValid })
  }

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error'
  }

  render() {
    const { formErrors, formValid } = this.state

    return (
      <div className="mt-4 mb-4">
        <div className="row flex-row justify-content-center">
          <div className="col-lg-5">
            <h4>Reset your password</h4>
            <div className="mt-2 mb-2">
              <FormErrors formErrors={formErrors} />
            </div>
            <form name="form" onSubmit={this.handleSubmit}>
              <div
                className={`form-group ${this.errorClass(formErrors.password)}`}
              >
                <label>
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  onChange={this.handleChange}
                />
              </div>
              <div
                className={`form-group ${this.errorClass(
                  formErrors.password_confirmation
                )}`}
              >
                <label>
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="password_confirmation"
                  onChange={this.handleChange}
                />
              </div>
              <button
                type="submit"
                name="submit"
                className="btn btn-primary"
                disabled={!formValid}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword
