import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { session } from './session.reducer'
import { sport } from './sport.reducer'
import { record } from "./record.reducer"



export const reducers = (history) => combineReducers({
    router: connectRouter(history),
    session,
    sport,
    record
})