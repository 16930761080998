import {axiosInstance} from '../helpers'


const url = '/activation'

const create = (params, token) => axiosInstance.create({url: `${url}/${token}`, params: params})

const get = token => axiosInstance.get({url: `${url}/${token}`})

const update = params => axiosInstance.update({url: url, params: params})


export const activateServices = {
  create,
  update,
  get,
}