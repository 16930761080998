import {axiosInstance} from '../helpers'


const url = '/confirmation'

const create = token => axiosInstance.create({url: `${url}/${token}`, params: null})

const update = token => axiosInstance.update({url: `${url}/${token}`, params: null})


export const confirmationServices = {
  create,
  update,
}