import {axiosInstance} from '../helpers'


const url = '/record'

const create = params => axiosInstance.create({url: url, params: params})

const get = () => axiosInstance.get({url: url, params: null})

const update = params => axiosInstance.update({url: url, params: params})

const destroy = params => axiosInstance.destroy({url: `${url}?${params}`, params: null})


export const recordServices = {
  create,
  get,
  update,
  destroy,
}
