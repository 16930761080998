import {axiosInstance} from '../helpers'


const url = '/survey'

const update = params => axiosInstance.update({url: url, params: params})


export const surveyServices = {
  update
}
