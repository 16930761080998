import {axiosInstance} from '../helpers'


const url = '/consent-form'

const create = params => axiosInstance.create({url: url, params: params}, {'responseType': 'blob'})


export const consentFormServices = {
  create,
}