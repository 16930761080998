import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { sessionActions } from '../../actions'
import { LoginModal } from '../Modals'

import dots from '../../images/Misc/siprc_dots.png'

class Topbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showLogin: false,
    }

    this.handleLogout = this.handleLogout.bind(this)
    this.toggleLogin = this.toggleLogin.bind(this)
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
  }

  handleLogout(event) {
    event.preventDefault()
    const { dispatch } = this.props
    dispatch(sessionActions.destroy())

    this.setState({
      showLogin: false,
    })
  }

  toggleLogin() {
    const { showLogin } = this.state

    this.setState({
      showLogin: !showLogin,
    })
  }

  handleLoginSubmit() {
    const { isAuthenticated } = this.props

    if (isAuthenticated) {
      this.setState({
        showLogin: false,
      })
    }
  }

  render() {
    const { isAuthenticated, username } = this.props
    const { showLogin } = this.state

    return (
      <div>
        <LoginModal
          showModal={showLogin}
          isAuthenticated={isAuthenticated}
          onClose={() => this.toggleLogin()}
          onSubmit={() => this.handleLoginSubmit()}
        />
        <div className="topbar-wrapper">
          <div className="container">
            <div className="topbar">
              <div>
                <span className="tablet-hidden topbar-widgets-quote">
                  <span
                    className="accent-color-1"
                    style={{ letterSpacing: '1px', paddingRight: '10px' }}
                  >
                    SHRed Concussions UK{' '}
                  </span>
                </span>
                <img
                  alt="sports dots"
                  style={{ height: '22px', paddingRight: '10px' }}
                  src={dots}
                />
                <span className="tablet-hidden topbar-widgets-quote">
                  “Turn your setbacks into comebacks”
                </span>
              </div>
              <div>
                <ul className="topbar-widgets">
                  {!isAuthenticated ? (
                    <ul className="topbar-widgets">
                      <li className="mr-2">
                        <span className="btn-custom" onClick={this.toggleLogin}>
                          Login
                        </span>
                      </li>
                      <li className="mr-2">
                        <Link className="btn-custom" to="/signup">
                          Signup
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <ul className="topbar-widgets">
                      <li className="mr-2">
                        <Link className="btn-custom" to="/profile/settings">
                          Settings
                        </Link>
                      </li>
                      <li>
                        <span
                          className="btn-custom"
                          onClick={this.handleLogout}
                        >
                          Logout
                        </span>
                      </li>
                    </ul>
                  )}
                  <ul className="topbar-widgets">
                    {isAuthenticated && (
                      <ul className="topbar-widgets">
                        <li className="mr-2"></li>
                        <li className="mr-2">
                          <span className="muted-color">{username}</span>
                        </li>
                      </ul>
                    )}
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { isAuthenticated, username } = state.session

  return {
    isAuthenticated,
    username,
  }
}

export default connect(mapStateToProps)(Topbar)
