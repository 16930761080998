import {axiosInstance} from '../helpers'


const url = '/reminder'

const create = params => axiosInstance.create({url: url, params: params})


export const reminderServices = {
  create
}
