import React from 'react'
import { Link } from 'react-router-dom'

export const NotFound = () => (
  <div style={{ height: '90vh', display: 'flex' }}>
    <div style={{ margin: '0 auto', marginTop: '45vh' }}>
      <h2>
        Page Not Found
      </h2>
      <p>
        Sorry, there is nothing to see here.
      </p>

      <div className="text-center">
        <p>
          <Link to="/">
            Back to Home
          </Link>
        </p>
      </div>
    </div>
  </div>
)
