import jwtDecode from 'jwt-decode'

import {sessionConstants} from '../constants'

let token = sessionStorage.getItem('token')
let email = ''
let state = ''
let role = ''
let firstName = ''
let city = ''
let requiresConsent = true
let requiresParentConsent = false
let parentEmail = ''

if (token) {
  console.log(JSON.stringify(jwtDecode(token)))
  email = jwtDecode(token).email
  state = jwtDecode(token).state
  role = jwtDecode(token).role
  firstName = jwtDecode(token).first_name
  requiresConsent = jwtDecode(token).requires_consent
  requiresParentConsent = jwtDecode(token).requires_parent_consent
  parentEmail = jwtDecode(token).parent_email
  city = jwtDecode(token).city
}

const initialState = email
  ? {
    token: token,
    username: email,
    state: state,
    role: role,
    firstName: firstName,
    loading: false,
    isAuthenticated: true,
    requiresConsent: requiresConsent,
    requiresParentConsent: requiresParentConsent,
    parentEmail: parentEmail,
    city: city
  }
  : {
    loading: false,
  }

export function session(state = initialState, action) {
  switch (action.type) {
    case sessionConstants.LOGIN_REQUEST:
      return {
        loading: true,
        error: false,
        errors: [],
      }
    case sessionConstants.LOGIN_SUCCESS:
    case sessionConstants.SESSION_UPDATE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        state: jwtDecode(action.token).state,
        role: jwtDecode(action.token).role,
        username: jwtDecode(action.token).email,
        firstName: jwtDecode(action.token).first_name,
        requiresConsent: jwtDecode(action.token).requires_consent,
        requiresParentConsent: jwtDecode(action.token).requires_parent_consent,
        parentEmail: jwtDecode(action.token).parent_email,
        city: jwtDecode(action.token).city,
        token: action.token,
        error: false,
        errors: [],
      }
    case sessionConstants.LOGIN_FAILURE:
      return {
        loading: false,
        isAuthenticated: false,
        token: null,
        username: null,
        error: true,
        errors: action.errors,
      }
    case sessionConstants.LOGOUT_REQUEST:
    case sessionConstants.LOGOUT_SUCCESS:
      return {
        loading: false,
      }
    default:
      return state
  }
}
