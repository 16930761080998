import {axiosInstance} from '../helpers'


const url = '/new-injury'

const create = params => axiosInstance.create({url: url, params: params})

const get = () => axiosInstance.get({url: url, params: null})


export const newInjuryServices = {
  create,
  get,
}